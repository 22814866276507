import {FrontendConfiguration} from "@common/configuration";
import {CancelAndRefundObject, CancellationOverridePayload, Fee} from "@common/typing";
import {DropzoneView} from "../../components/inputs/dropzone/DropzoneView";
import {datadogLogs} from "@datadog/browser-logs";
import {Image, Modal} from "@vacasa/react-components-lib";
import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {
  AboutCancellation,
  Button,
  ReviewRefund,
  CancellationSchedule,
  RefundSummary,
  CancelReservationMessageModal,
  ReservationErrorModal,
} from "../../components";
import {
  BOOKING_FEE_NAME,
  CANCELLATION_SCHEDULE_TITLE,
  CANCEL_RESERVATION_TYPE,
  MANUAL_OVERRIDE_TYPE,
  TRIP_PROTECTION_FEE_NAME,
  REFUND_SUMMARY_TITLE,
} from "../../constants";
import styles from "./Review.module.scss";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {
  cancelReservation,
  displayWarningCancellationModal,
  hideWarningCancellationModal,
  setAmountValidationError,
  setCurrentPathname,
  setOverrideInfo,
} from "../../store/cancellationWorkflowSlice";
import {getEligibleFees, getOverrideRent, getOverrideTaxes, getUserInfo} from "../../utils";
import {cancelOverrideReservation, setAboutCancellationData} from "../../store/cancellationWorkflowSlice";

export const Review: React.FunctionComponent<any> = () => {
  const dispatch = useAppDispatch();
  const reservation = useSelector((state: RootState) => state.cancellationWorkflow.reservation);
  const isAirbnbChannel = reservation?.data?.isAirbnbChannel;
  const adjustmentPreview = useSelector((state: RootState) => state.finance.adjustmentPreview);
  const cancellationSchedule = useSelector((state: RootState) => state.cancellationWorkflow.cancellationSchedule);
  const overrideData = useSelector((state: RootState) => state.cancellationWorkflow?.override);
  const cancellationDetails = useSelector((state: RootState) => state.cancellationWorkflow?.aboutCancellation);
  const isCancellingReservation = useSelector((state: RootState) => state.cancellationWorkflow?.reservation.action.cancelling);
  const paymentData = useSelector((state: RootState) => state.finance.payment);
  const aboutCancellationData = useSelector((state: RootState) => state.cancellationWorkflow.aboutCancellation);
  const {email: userEmail} = getUserInfo();

  const client = FrontendConfiguration.getVacasaClient();
  const originId = FrontendConfiguration.getOriginId();

  const initialCheckedValue: boolean = JSON.parse(localStorage.getItem("skipModalCancellation")) || false;
  const [checked, setChecked] = useState<boolean>(initialCheckedValue);

  const navigate = useNavigate();
  const {pathname} = useLocation();
  const totalBookingFee: string = (paymentData?.totalAmountPaid - Number(overrideData?.refund?.refundAmount)).toFixed(2);

  //Get the eligible fees based on the options selected. Get the override taxes and override rent
  const eligibleFees = getEligibleFees(adjustmentPreview?.data?.attributes?.preview_finances?.fees, reservation?.hasTripProtection);
  const overrideTaxes = getOverrideTaxes(adjustmentPreview?.data?.attributes?.preview_finances?.taxes);
  const overrideRent = getOverrideRent(adjustmentPreview?.data?.attributes?.preview_finances?.rent);

  //Map the eligible fees to re-calculate the fee amount based on options selected and return the fee id, name and amount
  const overrideFees = eligibleFees?.map((fee: Fee) => {
    const isTripProtectionFee = fee.name === TRIP_PROTECTION_FEE_NAME;
    const isBookingFee = fee.name === BOOKING_FEE_NAME;
    const isTripProtectionOverridden = overrideData?.refund?.tripProtectionAmount !== "";
    let amount: string;

    if (reservation?.hasTripProtection && isTripProtectionFee && isTripProtectionOverridden) {
      amount = Number(overrideData?.refund?.tripProtectionAmount).toFixed(2);
    } else if (reservation?.hasTripProtection && isBookingFee && !isTripProtectionOverridden) {
      amount = fee.amount;
    } else if (reservation?.hasTripProtection && isTripProtectionFee && !isTripProtectionOverridden) {
      amount = fee.amount;
    } else if (reservation?.hasTripProtection && isBookingFee && isTripProtectionOverridden) {
      amount = (Number(totalBookingFee) - Number(overrideData?.refund?.tripProtectionAmount)).toFixed(2);
    } else {
      amount = totalBookingFee;
    }

    return {id: fee.id, name: fee.name, amount};
  });

  const cancelAndRefundObject: CancelAndRefundObject = {
    adjustment_preview_id: adjustmentPreview?.data?.id,
    amount: adjustmentPreview?.recommendedRefund,
    cancelled_by: userEmail,
    client,
    currency: paymentData?.currencyCode,
    legacy_reservation_cancellation_tag_id: cancellationDetails?.reason?.id,
    legacy_reservation_id: reservation?.data?.attributes?.legacy_reservation_id,
    notes: cancellationDetails?.internalNotes,
    origin_id: originId,
  };

  const cancellationOverrideObject: CancellationOverridePayload = {
    type: CANCEL_RESERVATION_TYPE,
    amount: Number(overrideData?.refund?.refundAmount),
    notes: cancellationDetails?.internalNotes,
    origin_id: originId,
    override: {
      line_items: {
        fees: overrideFees,
        rent: overrideRent,
        taxes: overrideTaxes,
      },
      booked_currency: paymentData?.currencyCode,
      type: MANUAL_OVERRIDE_TYPE,
      override_reason: overrideData?.reason?.name,
      override_note: overrideData?.notes,
      override_approved_by_email: overrideData?.authorizeBy,
    },
    client,
    legacy_reservation_id: reservation?.data?.attributes?.legacy_reservation_id,
    legacy_reservation_cancellation_tag_id: cancellationDetails?.reason?.id,
    cancelled_by: userEmail,
  };

  useEffect(() => {
    dispatch(setCurrentPathname(pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = () => {
    localStorage.setItem("skipModalCancellation", JSON.stringify(!checked));
    setChecked(JSON.parse(localStorage.getItem("skipModalCancellation")));
  };

  const handleCancellation = async () => {
    if (overrideData?.checked) {
      dispatch(hideWarningCancellationModal());
      await dispatch(
        cancelOverrideReservation({
          reservationId: reservation?.data?.id,
          cancellationOverrideObject,
        })
      ).unwrap();
      navigate(`/${reservation?.data?.attributes?.legacy_reservation_id}/email`);
      return;
    }

    if (cancelAndRefundObject.amount > paymentData.totalAmountPaid) {
      dispatch(
        setAmountValidationError({
          recommendedRefund: adjustmentPreview?.recommendedRefund,
          totalAmountPaid: paymentData.totalAmountPaid,
        })
      );
      datadogLogs.logger.info("Recommended Refund is more than amount paid.", {
        reservationId: reservation?.data?.attributes?.legacy_reservation_id,
      });
      return;
    }

    dispatch(hideWarningCancellationModal());
    await dispatch(cancelReservation({reservationId: reservation?.data?.id, cancellationObject: cancelAndRefundObject})).unwrap();
    navigate(`/${reservation?.data?.attributes?.legacy_reservation_id}/email`);
  };

  const handleClickCancellation = async () => {
    await handleCancellation();
  };

  const handleClickCancellationModal = async () => {
    const modalIsChecked = JSON.parse(localStorage.getItem("skipModalCancellation"));
    if (modalIsChecked) {
      await handleCancellation();
    } else {
      dispatch(displayWarningCancellationModal());
    }
  };

  const handleBackStep = () => {
    dispatch(
      setOverrideInfo({
        ...overrideData,
        authorizeBy: null,
      })
    );
    navigate(`/${reservation?.data?.attributes?.legacy_reservation_id}`);
  };

  const handleFileDeletion = () => {
    dispatch(
      setAboutCancellationData({
        ...aboutCancellationData,
        file: null,
      })
    );
  };

  return (
    <>
      <div className="flex">
        <div className="columnFlex">
          <div className="box">
            <AboutCancellation title="About the Cancellation" />
          </div>
          {!isAirbnbChannel && <ReviewRefund />}
          {isAirbnbChannel && aboutCancellationData?.file && (
            <DropzoneView selectedFile={aboutCancellationData?.file} title="Attachments" onChange={handleFileDeletion} />
          )}
          <div className={styles.buttonsBox}>
            <Button label="Edit Details" mode="primaryOutlined" disabled={false} onClick={handleBackStep} />
            {!isAirbnbChannel && <Button label="Process Cancellation" mode="primary" disabled={false} onClick={handleClickCancellationModal} />}
          </div>
        </div>
        <div className="columnFlex">
          <RefundSummary title={REFUND_SUMMARY_TITLE} />
          <div className="innerMarginBox">
            <CancellationSchedule title={CANCELLATION_SCHEDULE_TITLE} schedule={cancellationSchedule} />
          </div>
        </div>
      </div>
      <Modal showModal={isCancellingReservation} setShowModal={() => {}} size="small" canExit={false}>
        <div className={styles.cancellingMessage}>
          <Image.Spinner style={{display: "flex"}} width={50} />
          <span className={styles.message}>Cancelling the reservation...</span>
        </div>
      </Modal>
      <CancelReservationMessageModal onClick={handleClickCancellation} onChange={handleInputChange} checked={checked} />
      <ReservationErrorModal />
    </>
  );
};
